import React from "react";

function TermsOfService() {
  return (
    <div className="container mx-auto my-8 px-4">
      <h1 className="text-4xl font-bold mb-8 text-center mb-2">תנאי שימוש </h1>
      <p>
        אנו מברכים אותך על השימוש באתר האינטרנט שלנו, שמטרתו העיקרית היא לספק לך תוכן, שירותים, מידע ומוצרים שיכולים לסייע לך 
        בתהליכים שונים בחייך האישיים או המקצועיים. תנאי שימוש אלו מהווים את ההסכם המשפטי המחייב בינך (להלן "המשתמש") לבין מפעילי האתר 
        (להלן "המפעיל"), אשר נוגע לשימוש באתר זה, התכנים המוצגים בו והשירותים הניתנים דרכו. עליך לקרוא בעיון את כל תנאי השימוש המפורטים במסמך זה 
        לפני תחילת השימוש באתר. אם אינך מסכים לאחד או יותר מהתנאים המפורטים להלן, עליך להימנע משימוש באתר. 
      </p>

      <h2 className="text-3xl font-bold mt-6 mb-4">1. קבלת התנאים והשימוש באתר</h2>
      <p>
        בשימושך באתר, אתה מצהיר ומאשר כי קראת והבנת את התנאים המפורטים במסמך זה, וכי אתה מסכים לציית להם במלואם, ללא יוצא מן הכלל. 
        השימוש באתר מותנה בהסכמתך המלאה לכל סעיפי התנאים, לרבות כל שינוי עתידי שיבוצע בהם. תנאים אלו תקפים לכל סוגי המשתמשים, 
        לרבות גולשים מזדמנים, משתמשים רשומים, ומשתמשים מסחריים.
      </p>
      <p>
        השימוש באתר מותנה בכך שאתה מעל גיל 18 או שקיבלת אישור מהוריך או מאפוטרופוס חוקי לשימוש באתר. אנו שומרים לעצמנו את הזכות 
        לאמת את גילך ולמנוע ממך שימוש באתר אם לא תוכל לספק ראיות מספקות לגילך.
      </p>

      <h2 className="text-3xl font-bold mt-6 mb-4">2. שינויים בתנאי השימוש</h2>
      <p>
        המפעיל שומר לעצמו את הזכות, לפי שיקול דעתו הבלעדי וללא הודעה מוקדמת, לעדכן, לתקן, להוסיף או להסיר חלקים מהתנאים המפורטים 
        במסמך זה בכל עת. כל שינוי בתנאי השימוש ייכנס לתוקף עם פרסומו באתר, והשימוש שלך באתר לאחר ביצוע השינויים ייחשב כהסכמה מלאה 
        שלך לתנאים המעודכנים. עליך לבדוק מעת לעת את תנאי השימוש כדי להתעדכן בכל שינוי אפשרי.
      </p>
      <p>
        באחריותך לבדוק את התנאים לפני כל שימוש באתר. במקרה שאינך מסכים לשינויים שבוצעו בתנאים, עליך להפסיק להשתמש באתר 
        וליידע אותנו על כך בכתובת הדוא"ל הרשומה מטה.
      </p>

      <h2 className="text-3xl font-bold mt-6 mb-4">3. זכויות שימוש באתר ובתכנים</h2>
      <p>
        כל התכנים, העיצובים, המידע, התמונות, הווידאו, הטקסטים והאלמנטים הגרפיים (להלן "התכנים") הכלולים באתר זה מוגנים על פי חוקי 
        זכויות יוצרים וחוקי קניין רוחני אחרים במדינת ישראל ובמדינות נוספות. השימוש באתר זה מיועד למטרות פרטיות ואישיות בלבד. 
        אין להעתיק, לשכפל, להפיץ, להציג, למכור, לשדר או להשתמש בתכנים אלו לכל מטרה מסחרית או ציבורית ללא אישור בכתב מהמפעיל.
      </p>
      <p>
        המפעיל שומר לעצמו את כל הזכויות שלא הוענקו במפורש במסמך זה. כל שימוש לא מורשה בתכני האתר עשוי להוביל לפעולה משפטית נגדך, 
        כולל תביעות נזיקין ותלונות פליליות, בהתאם לחוקי מדינת ישראל.
      </p>

      <h2 className="text-3xl font-bold mt-6 mb-4">4. שימוש בלתי חוקי או אסור</h2>
      <p>
        אתה מתחייב שלא לעשות באתר כל שימוש בלתי חוקי, בלתי מוסרי, פוגעני או מפר תנאים אלו. שימוש כזה כולל אך אינו מוגבל ל:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>הפצת תוכן בלתי חוקי, פוגעני, משמיץ, או כזה המפר זכויות קניין רוחני של צדדים שלישיים.</li>
        <li>ניסיון לגשת למערכות המחשוב של האתר ללא הרשאה או פגיעה באבטחת המידע של האתר.</li>
        <li>שימוש באתר לצרכי הפצת דואר זבל, תוכנות זדוניות או פרסום שאינו מאושר.</li>
        <li>שימוש באתר לצורך פגיעה במשתמשים אחרים, כולל גניבת מידע אישי.</li>
      </ul>

      <h2 className="text-3xl font-bold mt-6 mb-4">5. הגבלת אחריות</h2>
      <p>
        האתר וכל התכנים והשירותים המוצעים בו מסופקים "כפי שהם" (As Is) וללא כל אחריות מכל סוג שהוא, בין אם מפורשת או משתמעת. 
        המפעיל אינו נושא באחריות לכל נזק, הפסד או תוצאה אחרת הנגרמת משימוש באתר, לרבות אך לא מוגבל ל:
      </p>
      <ul className="list-disc list-inside mb-4">
        <li>תקלות טכניות, בעיות גישה, או השבתה של האתר.</li>
        <li>פגיעות בפרטיות הנובעות משימוש במידע אישי או ציבורי באתר.</li>
        <li>נזקים שנגרמו עקב שימוש בלתי נכון במידע או בשירותים המוצעים באתר.</li>
      </ul>
      <p>
        השימוש באתר הוא באחריות המשתמש בלבד. אנו ממליצים להפעיל שיקול דעת ולבדוק כל מידע שמופיע באתר טרם הסתמכות עליו.
      </p>

     

      <h2 className="text-3xl font-bold mt-6 mb-4">6. פרטיות ושמירת מידע</h2>
      <p>
        השימוש באתר כפוף למדיניות הפרטיות של האתר, המסבירה את אופן איסוף המידע האישי שלך, עיבודו ושמירתו. 
        עליך לקרוא בעיון את מדיניות הפרטיות לפני תחילת השימוש באתר. המשך השימוש באתר מהווה את הסכמתך למדיניות הפרטיות שלנו.
      </p>

      <h2 className="text-3xl font-bold mt-6 mb-4">7. שיפוי</h2>
      <p>
        אתה מתחייב לשפות את המפעיל, עובדיו, מנהליו ושותפיו בגין כל תביעה, נזק, הפסד, או עלות (כולל שכר טרחת עורכי דין) הנובעים משימושך באתר 
        באופן שאינו עומד בתנאי שימוש אלו או מכל הפרה של התחייבויותיך כפי שמפורט במסמך זה.
      </p>

      <h2 className="text-3xl font-bold mt-6 mb-4">8. יצירת קשר</h2>
      <p>
        אם יש לך שאלות, הערות או בקשות הנוגעות לתנאי השימוש, תוכל לפנות אלינו באמצעות דוא"ל:
        <a href="mailto:support@example.com" className="text-blue-600 underline"> il.toplaptop.net@gmail.com</a>
      </p>
      <p>
        אנו נשתדל להגיב לכל פנייה תוך 7 ימי עסקים, אך ייתכן שיידרש זמן נוסף לטיפול בפניות מורכבות.
      </p>
      <h2 className="text-3xl font-bold mt-6 mb-4"> הסכמה לפעולה באתר</h2>
        <p>
            כל פעולה באתר, כולל אך לא מוגבלת להתחלת שאלונים, מילוי טפסים, או לחיצה על כפתורים, 
            מהווה את הסכמתך המלאה והמפורשת לכל תנאי השימוש ולמדיניות הפרטיות כפי שמפורטים במסמכים אלו. 
            אם אינך מסכים לתנאים, אנא הימנע משימוש באתר או משירותיו.
        </p>
        <p>
            באחריות המשתמש לקרוא בעיון את תנאי השימוש ומדיניות הפרטיות לפני תחילת כל פעולה באתר. 
            המשך השימוש באתר מהווה הכרה בכך שקראת והסכמת לכל התנאים המפורטים.
        </p>
    </div>
  );
}

export default TermsOfService;
